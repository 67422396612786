// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/caseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-customer-story-tsx": () => import("./../../../src/templates/customerStory.tsx" /* webpackChunkName: "component---src-templates-customer-story-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-flex-tsx": () => import("./../../../src/templates/flex.tsx" /* webpackChunkName: "component---src-templates-flex-tsx" */),
  "component---src-templates-landing-tsx": () => import("./../../../src/templates/landing.tsx" /* webpackChunkName: "component---src-templates-landing-tsx" */),
  "component---src-templates-press-release-tsx": () => import("./../../../src/templates/pressRelease.tsx" /* webpackChunkName: "component---src-templates-press-release-tsx" */),
  "component---src-templates-product-feature-tsx": () => import("./../../../src/templates/productFeature.tsx" /* webpackChunkName: "component---src-templates-product-feature-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-redirect-tsx": () => import("./../../../src/templates/redirect.tsx" /* webpackChunkName: "component---src-templates-redirect-tsx" */),
  "component---src-templates-resource-category-tsx": () => import("./../../../src/templates/resourceCategory.tsx" /* webpackChunkName: "component---src-templates-resource-category-tsx" */),
  "component---src-templates-resources-overview-tsx": () => import("./../../../src/templates/resourcesOverview.tsx" /* webpackChunkName: "component---src-templates-resources-overview-tsx" */),
  "component---src-templates-solution-tsx": () => import("./../../../src/templates/solution.tsx" /* webpackChunkName: "component---src-templates-solution-tsx" */),
  "component---src-templates-solutions-overview-tsx": () => import("./../../../src/templates/solutionsOverview.tsx" /* webpackChunkName: "component---src-templates-solutions-overview-tsx" */),
  "component---src-templates-video-tsx": () => import("./../../../src/templates/video.tsx" /* webpackChunkName: "component---src-templates-video-tsx" */),
  "component---src-templates-webinar-tsx": () => import("./../../../src/templates/webinar.tsx" /* webpackChunkName: "component---src-templates-webinar-tsx" */),
  "component---src-templates-where-to-buy-tsx": () => import("./../../../src/templates/whereToBuy.tsx" /* webpackChunkName: "component---src-templates-where-to-buy-tsx" */)
}

